<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form class="addForm" ref="form" :model="formData" label-width="120px">
      <el-form-item label="名称" prop="name" :rules="rules">
        <el-input v-model="formData.name" size="small" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'

export default {
  name: 'addOrEdit',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        name: ''
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .addForm {
    .el-form-item {
      .el-input {
        width: 400px;
      }

      .tips {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        line-height: 30px;
      }

      .el-range-separator {
        padding: 0;
      }
    }
  }

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 10px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
}
</style>
